.tableContainer {
  background-color: white;
  border-radius: 20px;
  flex-grow: 1;
  width: 99%;
  overflow-x: auto;
  margin: 0.5%
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px
}

.tableTitle {
  font-size: 20px;
  font-weight: 500;
}

.tableOptions {
  display: flex;
  justify-content: center;
}

.table {
  margin: 0.5%;
  width: 99%;
  border-collapse: collapse;
}

.table thead {
  border-bottom: 2px solid #ebeaf4;
  text-transform: capitalize
}

.table thead th {
  color: #4b4b4b;
  padding: 15px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.table tbody td {
  border-top: 1px solid #ebeaf4;
  padding: 15px;
  font-size: 15px;
  text-align: center;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px
}

.tablePagination {
  text-align: center;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablePagination button,
.tablePagination div {
  padding: 8px 16px;
  margin: 1px;
  border-radius: 30px;
  background-color: #ffffff;
  color: #606060;
  border: none;
  cursor: pointer;
  user-select: none;
}

.tablePagination button:hover {
  background-color: #f4eefd;
}

.tablePagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tableSearch {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  color: #b5b5b5;
  margin: 0 10px 0 10px
}

.tableSearch::placeholder {
  color: #b5b5b5;
}

.tableItems {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  color: #b5b5b5;
}

.tableEmpty {
  text-align: center;
  padding: 30px;
}

.tableLogo {
  height: 80px;
  display: block;
  margin: 0 auto;
}

.createNewButton, .exportButton {
  border-radius: 5px;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 10px 0 10px;
  cursor: pointer
}

.createNewButton {
  background-color: #5445F8;
}

.exportButton {
  background-color: #62CE73;
}

.createNewButton:hover {
  background-color: #695cf6;
}

.formWrapper {
  width: 60vw;
}

.actionButtons {
  display: flex;
  color: white;
}

.editButton, .deleteButton, .showButton {
  padding: 7px;
  border-radius: 10px;
  height: 15px;
  width: 15px;
  margin: 2px;
  cursor: pointer;
}

.editButton {
  background-color: #5445F8;
}

.deleteButton {
  background-color: #CE6262;
}

.showButton {
  background-color: #62CE73;
}

@media only screen and (max-width: 1200px) {
  .formWrapper {
    width: 90vw;
  }
}

.tableMobile {
  display: none;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    display: none;
  }

  .tableMobile {
    color: #4b4b4b;
    font-weight: 800;
    display: block;
  }
  
  .tableContainer {
    width: 90%;
  }

  .tableHeader {
    flex-direction: column;
  }
  
  .tableItems {
    margin-top: 5px;
  }

  .tableSearch {
    margin: 5px 0 0 0
  }
}


@media (max-width: 768px) {
  .tableOptions {
    flex-direction: column;
  }
}


input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #5445F8;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #695cf6;
}