@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

body {
    background-color: #F9FAFB;
    font-family: 'Montserrat', sans-serif;
    margin: 2%;
}

.loginForm {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    justify-content: space-between;
    gap: 8px;
    border-radius: 10px;
}

.loginSection {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.loginInput {
    font-size: 16px;
    height: 30px;
    border-radius: 10px;
    border: 0;
    border: 1px solid #f3f3f3;
    padding: 5px 20px;
}

.loginInput:focus{
    outline: none;
}

.loginInput::placeholder {
    color: #d9d9d9;
}

.signIn {
    background-color: #1e6ea8;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.title {
    font-size: 30px;
    font-weight: 400;
    padding: 20px;
    color: #031a61
}



.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.leftpane {
    width: 20%;
    height: 80vh;
    background-color: /*#FF3366*/ #ffffff;
    border-radius: 15px;
}

.centerpane {
    width: 50%;
    height: 100%;
    background-color: #fff;
    border-collapse: collapse;
    border-radius: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.npButtons {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 2%;
    color: #1E6EA8;
    user-select: none;

}

.pButton {
    background-color: #E8F0FE;
    padding: 10px 28px 10px 28px;
    border-radius: 7px 0 0 7px;
    cursor: pointer;
    font-weight: 800;
}

.nButton {
    background-color: #E8F0FE;
    padding: 10px 28px 10px 28px;
    border-radius: 0 7px 7px 0;
    cursor: pointer;
}

.cButton {
    background-color: #E8F0FE;
    padding: 10px 35px 10px 35px;
}


.question {
    width: 60%;
    user-select: none;
}

.questionOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin: 4%;
    color: #1E6EA8;
    user-select: none;
}

.questionOption {
    background-color: #E8F0FE;
    padding: 10px 25px 10px 25px;
    border-radius: 10px;
    cursor: pointer;
}


.rightpane {
    width: 20%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    user-select: none;
}

.toppane {
    width: 92%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 10px;
    margin: 1% 2% 1% 2%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
}

.timeLeft {
    background-color: #1E6EA8;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 8%;
    color: white;
    border-radius: 10px;
    padding: 15px 0 15px 0;
}

.timeLeftTitle {
    font-size: 14px;
}

.endExam {
    background-color: #E56D6D;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 8%;
    color: white;
    border-radius: 10px;
    padding: 15px 0 15px 0;
    cursor: pointer;
}

.endExamTitle {
    font-size: 14px;
}

.opticalForm {
    background-color: #E8F0FE;
    border: 1px solid #1E6EA8;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-radius: 10px;
}

.options {
    display: flex;
    flex-direction: row;
    color: #1E6EA8;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding: 5% 0 5% 0;
    border-bottom: 1px solid #1E6EA8;
}

.options:last-child {
    border-bottom: none;
}



.letterOption {
    border: 1.3px solid #1E6EA8;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:calc(10vw / 6);
    height:calc(10vw / 6);
    user-select: none;
    font-size: 14px;
}



.numberOption {
    margin: 2px;
    padding: 2px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
}

.selectedLetter {
    background-color: black;
}

.selectedQuestionOption {
    background-color: #1E6EA8;
}


input[type=radio] {
    opacity: 0;
}


.activeQuestion {
    color: #E56D6D;
}

.examList {
    width: 100%;
}

.examCard {
    background-color: #fff;
    border-radius: 10px;
    margin: 2%;
    padding: 2%;
    width: 40%;
}

.profileInfo {
    background-color: #4FA2D1;
    border-radius: 10px;
    margin: 2%;
    padding: 2%;
    width: 40%;
    user-select: none;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logOut {
    background-color: #E56D6D;
    padding: 10px 20px 10px 20px;
    font-weight: 400;
    color: white;
    border-radius: 8px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    user-select: none;
    text-transform: capitalize;
}

.examCardBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 4%
}

.examTitle {
    font-size: 20px;
}
.examCardTime {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.examTime {
    font-size: 14px;
}

.examDate {
    font-size: 18px;
}

.examButton {
    display: flex;
    align-items: center;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    color: white;
}

.link {
    text-decoration: none;
    color: white
}

.errorMessage {
    font-size: 14px;
    color: #E56D6D;
}

.centerFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}


.paragraph {
    margin: auto;
}

.pageWrapper {
    width: 100%;
}

.questionId {
    margin: 0 3px;
    color: #f7b57b;
}

.optionRow {
    display: flex;
    align-items: center;
    margin: 5px 0;
    /*background-color: #fadcc3;*/
    user-select: none;
}

.option-result {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f7b57b;
    border-radius: 20px;
    margin: 0 3px;
    color: #f7b57b;
}

.correctAnswer {
    background-color: #7be56d;
    color: #7be56d
}


.choosenAnswer {
    background-color: #000000;
    color: #000000
}


.wrongAnswer {
    background-color: #e56d6d;
    color: #e56d6d
}




@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1000px) {
    body {
        padding: 0
    }
    
    .leftpane {
        display: none;
    }
    
    .centerpane {
        width: 65%;
    }
    .rightpane {
        width: 25%;
    }

    .letterOption  {
        font-size: 12px;
    }

    .examCard, .profileInfo {
        width: 75%;
    }
}


@media only screen and (max-width: 700px) {
    body {
        padding: 0
    }

    .leftpane {
        display: none;
    }
    
    .centerpane {
        width: 100%;
    }
    .rightpane {
        width: 100%;
    }
    .container {
        flex-direction: column;
    }

    .letterOption {
        width:calc(40vw / 6);
        height:calc(40vw / 6);
        font-size: 24px;
    }

    .questionOptions {
        width: 90%;
    }
}