body {
    font-family: "Helvetica", sans-serif;
  }

.sidebar {
    margin: 0;
    padding: 0;
    width: 15%;
    background-color: #F8F9FA;
    position: fixed;
    height: 100%;
    z-index: 1
}

.logoText { 
    text-align: center;
}

.logoLink {
    font-weight: bold;
    color: #2D3748;
    font-size: 14px;
    text-decoration: none;
}

.logOutAdmin {
    color: white;
    background-color: #CE6262;
    padding: 7.5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    border: none;
    margin-top: 20px;
    cursor: pointer
}

.logoSeperator {
    display: flex;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, rgb(224, 225, 226) 49.52%, rgba(224, 225, 226, 0) 100%);
    margin: 20px;
}


.sidebarItem {
    color: #A0AEC0;
    padding: 7.5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    fill: #4FA2D1;
    text-decoration: none;
}

.sidebarIcon {
    height: 15px;
    width: 15px;
    background-color: white;
    padding: 7.5px;
    margin: 7.5px 12px 7.5px 7.5px;
    border-radius: 12px;
}


.sidebarText {
    font-weight: bold;
}


.sidebarItem.active {
    background-color: white;
    color: #2D3748;
}

.sidebarItem.active > .sidebarIcon {
    background-color: #4FA2D1;
    fill: white;
}

.header {
    background-color: black;

}
.row {
    display: flex;
}
