
.card {
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 0.5%;
    margin: 0.5%
}

.cardTitle {
    color: #2D3748;
    font-weight: bold;
    display: flex;
    width: 100%;
    padding: 15px 0 0 15px
}

.cardBody {
    padding: 10px
}