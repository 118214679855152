.modalBackground {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    left: 0;
    top: 0;
    z-index: 50;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}


.modalDialog {
    padding: 10px 30px 10px 30px;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    user-select: none;
}

.modalAccept, .modalDeny{ 
    border: 0;
    color: white;
    padding: 8px 12px 8px 12px;
    border-radius: 100px;
    margin: 5px;
    cursor: pointer;
}

.modalAccept {
    background-color: #5445F8;
}

.modalDeny {
    background-color: #F740A1;
}

.modalDismiss {
    background-color: #CE6262;
}

.modalTitle {
    font-weight: 500;
    margin: 10px
}

@media only screen and (max-width: 992px) {
    .modalDialog {
        margin-bottom: 50%;
    }
    .modalBackground {
        display: block;
        overflow: auto;
    }
}
