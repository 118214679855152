.formWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.formElement {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
}

.formElement > label {
  font-size: 14px;
  font-weight: 500;
  color: #3f3f3f;
  margin-bottom: 5px;
}

.formInput {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  color: #b5b5b5;
  width: 100%;
  box-sizing: border-box;
}

.saveButton {
  background-color: #5445F8;
  border: 0;
  color: white;
  padding: 8px 12px 8px 12px;
  border-radius: 100px;
  margin: 5px;
  cursor: pointer;
  float: right;
}

.formText {
  color: red;
  float: left
}

.formElement {
  margin-bottom: 20px;
}

.multiple-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selected-values {
  display: flex;
  padding: 10px;
  color: #b5b5b5;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  color: #b5b5b5;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  
}

.selected-values .selected-option {
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #5445F8;
  color: #fff;
  padding: 3px;
  font-size: 10px;
}

.selected-values .placeholder {
  color: #808080;
}

.multiple-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: none;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  text-align: left;
  
}

.multiple-options .option {
  padding: 8px;
  cursor: pointer;
  font-size: 14px
}

.multiple-options .option:hover {
  background-color: #f1f1f1;
}

.multiple-options .option.selected {
  background-color: #5445F8;
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .formContainer {
    width: 100% !important;
  }
}