body {
    font-family: 'Helvetica', sans-serif;
    margin: 2%;
    background-color: #F8F9FA;
}

.pageContainer {
    width:  80%;
    float: right;
    display: flex;
    flex-wrap: wrap;
}

.pageContainer {
    margin: 20px 0 20px;
}

.btn {
    border: 0;
    border-radius: 8px;
    padding: 8px 15px 8px 15px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.btn-dark {
    color: white;
    background: linear-gradient(81.62deg, rgb(49, 56, 96) 2.25%, rgb(21, 25, 40) 79.87%);
}

.btn:hover {
    opacity: 0.9;
}

.fitImage {
    width: 100%;
}

.givenAnswerWrapper {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.answerOption {
    color: white;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.givenAnswerPercent {
    background-color: #9b9b9b;
    height: 50px;
    margin-left: 10px;
    border-radius: 25px;
}

.statCard {
    margin: 10px 0;
    padding: 20px 10px;
    border-radius: 10px;
    display: flex;
    flex: 1;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}


.uploadExcel {
    background-color: #62CE73;
    text-decoration: none;
    color: white;
    padding: 10px 20px 10px;
    border-radius: 20px;
    cursor: pointer
}



.infoCard {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(45deg, #6D80FE, #23D2FD);
    color: white;
    padding: 20px;
    margin: 0.5%;
}

.infoCardCount {
    font-size: 14px;
    font-weight: 600;
}

.infoCardTitle {
    font-size: 18px;
    font-weight: 300;
}
    